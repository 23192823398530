import { useMurI18n } from "@/common/i18n";


export function getBannerData() {
  const { 
    banner01Title, banner01SubTitle, banner02Title, banner02SubTitle ,
    banner03Title, banner03SubTitle, banner04Title, banner04SubTitle,
    banner05Title, banner05SubTitle, banner06Title, banner06SubTitle,
    banner07Title, banner07SubTitle
  } = getI18nObjc();
  return [
    {
      title: banner01Title,
      description: banner01SubTitle,
    },
    {
      title: banner02Title,
      description: banner02SubTitle
    },
    {
      title: banner03Title,
      description: banner03SubTitle
    },
    {
      title:banner04Title,
      description: banner04SubTitle
    },
    {
      title: banner05Title,
      description: banner05SubTitle
    },
    {
      title: banner06Title,
      description: banner06SubTitle
    },
    {
      title: banner07Title,
      description: banner07SubTitle
    },
  ]
}

export function getI18nObjc() {
  return useMurI18n('downloadPage');
}

export function shootImagesData() {
  const images = [];
  const imagesContext = import.meta.glob('@/assets/download/shoot/*.png');

  for (const path in imagesContext) {
    if (import.meta.env.MODE === 'development') {
      images.push(path);
    } else {
      images.push(path.replace('/src/assets/download/shoot/', '/assets/'));
    }
  }
  console.log(images);
  return images.sort((a, b) => a.localeCompare(b));
}
